<template>
  <div class="mHome">
    <div class="mHome-top">
      <div class="text">
        <p>一站式互联网定制</p>
        <div></div>
        <p>多行业·多类型·高品质</p>
      </div>
      <img v-lazy="topImg" alt="" srcset="" class="topImg">
      <div class="computer">
        <img v-lazy="screen" alt="" srcset="" class="screen">
        <img v-lazy="base" alt="" srcset="" class="base">
        <img v-lazy="shade" alt="" srcset="" class="shade">
      </div>
    </div>
    <div class="homeScene">
      <div class="homeScene-top">
        <img v-lazy="PXsceneTitleImg" alt="" srcset="">
      </div>
      <div class="homeScene-bottom">
        <div class="homeScene-bottom-list">
          <div class="homeScene-bottom-list-item" :style="{backgroundColor:item.title!=sceneTitle?'#FBFDFF':'#006BF5'}" v-for="(item,index) in sceneList.slice(0,4)" :key="index"
            @click="sceneTitleChange(item.title,item.name)">
            <img :src="item.img" alt="" srcset="" v-if="item.title!=sceneTitle">
            <img :src="item.imgCheck" alt="" srcset="" v-else>
            <div class="homeScene-bottom-list-item-line" :style="{backgroundColor:item.title!=sceneTitle?'#C6C6C6':'#FFFFFF'}"></div>
            <p :style="{color:item.title!=sceneTitle?'#000000':'#FFFFFF'}">{{ item.title }}</p>
          </div>
        </div>
        <div class="homeScene-bottom-list">
          <div class="homeScene-bottom-list-item" :style="{backgroundColor:item.title!=sceneTitle?'#FBFDFF':'#006BF5'}" v-for="(item,index) in sceneList.slice(4)" :key="index"
            @click="sceneTitleChange(item.title,item.name)">
            <img :src="item.img" alt="" srcset="" v-if="item.title!=sceneTitle" :style="{width:item.title=='站点配送'?'29px':'', height:item.title=='站点配送'?'23px':''}">
            <img :src="item.imgCheck" alt="" srcset="" v-else>
            <div class="homeScene-bottom-list-item-line" :style="{backgroundColor:item.title!=sceneTitle?'#C6C6C6':'#FFFFFF'}"></div>
            <p :style="{color:item.title!=sceneTitle?'#000000':'#FFFFFF'}">{{ item.title }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="homeBusiness">
      <div class="homeBusiness-top">
        <img v-lazy="businessTitleImg" alt="" srcset="">
      </div>
      <div class="homeBusiness-bottom">
        <div class="homeBusiness-bottom-list">
          <div class="homeBusiness-bottom-list-item" v-for="(item,index) in businessContentList" :key="index">
            <div class="homeBusiness-bottom-list-item-top" :style="item.backgroundStyle">
              <img :src="item.img" alt="" :class="'img'+item.i">
            </div>
            <div class="homeBusiness-bottom-list-item-bottom">
              <p>{{ item.title }}</p>
              <div></div>
              <p v-html="item.content"></p>
            </div>
          </div>
        </div>

        <!-- <div class="homeBusiness-bottom1" @touchstart="start($event)" @touchmove="move($event)">
          <div class="homeBusiness-bottom-left">
            <el-carousel :interval="5000" ref="carouselRef" @touchmove="move($event)" :autoplay="true" indicator-position="outside">
              <el-carousel-item v-for="(item,index) in businessList" :key="index">
                <img v-lazy="item.img" alt="" srcset="">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div> -->
      </div>
    </div>
    <div class="homeService">
      <div class="homeService-top">
        <img v-lazy="serviceTitle" alt="" srcset="">
      </div>
      <div class="homeService-bottom">
        <div class="homeService-bottom-list" v-for="(item,index) in serviceList" :key="index">
          <img :src="item.img" alt="" srcset="">
          <p>{{ item.title }}</p>
          <p>{{ item.span}}</p>
          <p>{{ item.span1 }}</p>
        </div>
      </div>
    </div>
    <div class="homeJob">
      <div class="homeJob-top">
        <img v-lazy="jobTitleImg" alt="" srcset="">
      </div>
      <div class="homeJob-bottom">
        <!-- <scroll-view scroll-x="true"> -->
        <div class="homeJob-bottom-list">
          <div class="homeJob-bottom-list-item" v-for="(item,index) in jobList" :key="index">
            <div class="homeJob-bottom-list-item-top">
              <img v-lazy="item.img" alt="">
            </div>
            <div class="homeJob-bottom-list-item-center">
              <p>{{ item.title }}</p>
              <span>{{ item.span }}</span>
              <span>{{ item.span1 }}</span>
              <span>{{ item.span2 }}</span>

            </div>
            <div class="homeJob-bottom-list-item-bottom">
              <p @click="navChange('求职定制')">立即了解</p>
              <img src="@/assets/Mi/home/job/next.png" alt="">
            </div>
          </div>
        </div>
        <!-- </scroll-view> -->

      </div>
    </div>

    <div class="homeWhy">
      <div class="homeWhy-top">
        <img v-lazy="whyChoose" alt="" srcset="">
      </div>
      <div class="homeWhy-bottom">
        <img :src="whyChooseBg" alt="">
        <div class="content">
          <div class="title">为什么千述科技是您最好的选择：</div>
          <p>我们是一家拥有专业开发团队，以严谨认真的态度对待每一项业务与</p>
          <p>挑战的公司。在当今纷繁的互联网环境中为每个客户提供最优质的定</p>
          <p>向服务，精准地满足客户的需求。</p>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  props: ['navTitle', 'casePopName'],
  data() {
    return {
      sceneTitle: '',
      startX: '',
      startY: '',
      moveY: '',
      moveX: '',
      startTime: '',
      topImg: require('@/assets/Mi/home/top.png'),
      screen: require('@/assets/Mi/home/screen.png'),
      base: require('@/assets/Mi/home/base.png'),
      shade: require('@/assets/Mi/home/shade.png'),
      PXsceneTitleImg: require('../../assets/Mi/home/scene/title.png'),
      sceneList: [
        {
          img: require('../../assets/Mi/home/scene/1.png'),
          imgCheck: require('../../assets/Web/home/scene/1check.png'),
          title: 'B2C单商户商城',
          name: 'shopping',
        },
        {
          img: require('../../assets/Mi/home/scene/2.png'),
          imgCheck: require('../../assets/Web/home/scene/2check.png'),
          title: '共享智能门店',
          name: '',
        },
        {
          img: require('../../assets/Mi/home/scene/3.png'),
          imgCheck: require('../../assets/Web/home/scene/3check.png'),
          title: '家政上门',
          name: 'housekeeping',
        },
        {
          img: require('../../assets/Mi/home/scene/4.png'),
          imgCheck: require('../../assets/Web/home/scene/4check.png'),
          title: '物业系统',
          name: 'digitization',
        },
        {
          img: require('../../assets/Mi/home/scene/5.png'),
          imgCheck: require('../../assets/Web/home/scene/5check.png'),
          title: '智能门店',
          name: 'unmanned',
        },
        {
          img: require('../../assets/Mi/home/scene/6.png'),
          imgCheck: require('../../assets/Web/home/scene/6check.png'),
          title: '站点配送',
          name: 'cloudTavern',
        },
        {
          img: require('../../assets/Mi/home/scene/7.png'),
          imgCheck: require('../../assets/Web/home/scene/7check.png'),
          title: '预约配送',
          name: 'cakeSubscribe',
        },
        {
          img: require('../../assets/Web/home/scene/8.png'),
          imgCheck: require('../../assets/Web/home/scene/8check.png'),
          title: 'CRM系统',
          name: 'crm',
        },

      ],
      businessTitleImg: require('../../assets/Mi/home/business/title.png'),
      businessList: [
        { img: require('../../assets/Mi/home/business/1.png'), title: '千述专业开发团队', index: 0 },
        { img: require('../../assets/Mi/home/business/2.png'), title: 'APP开发', index: 1 },
        { img: require('../../assets/Mi/home/business/3.png'), title: '智能软硬结合系统', index: 2 },
        { img: require('../../assets/Mi/home/business/4.png'), title: '网站定制开发', index: 3 },
        { img: require('../../assets/Mi/home/business/5.png'), title: '企业应用管理系统', index: 4 },
        { img: require('../../assets/Mi/home/business/6.png'), title: '微信小程序', index: 5 },

      ],
      serviceTitle: require('../../assets/Mi/home/service/title.png'),
      serviceList: [
        {
          img: require('../../assets/Mi/home/service/1.png'), title: '专业开发',
          span: '拥有七年项目开发经验的',
          span1: '技术与产品团队'
        },
        {
          img: require('../../assets/Mi/home/service/2.png'), title: '产品定制',
          span: '提供最优质开发方案，充',
          span1: '分满足用户需求',

        },
        {
          img: require('../../assets/Mi/home/service/3.png'), title: '按时交付',
          span: '严格把控开发进度，确保',
          span1: '产品快速上线'

        },
        {
          img: require('../../assets/Mi/home/service/4.png'), title: '售后保障',
          span: '保证产品上线后的跟踪运',
          span1: '营与实时维护'

        },
      ],
      jobTitleImg: require('../../assets/Mi/home/job/title.png'),
      jobList: [
        {
          img: require('../../assets/Mi/home/job/1.png'), title: '简历优化',
          span: '互联网资深大咖辅导并设计，扬',
          span1: '长避短用简历语言写专业简历，', span2: '提高简历通过率大幅度',
        },
        {
          img: require('../../assets/Mi/home/job/2.png'), title: '精准一对一',
          span: '配备专属顾问，一对一沟通深挖',
          span1: '个人能力，了解自身优势，梳理', span2: '个人核心竞争力',
        },
        {
          img: require('../../assets/Mi/home/job/3.png'), title: '模拟面试',
          span: '提供HR仿真场景面试，解析面试',
          span1: '常见问题，分析面试表现，提出改', span2: '进建议',
        },
      ],
      businessContentList: [
        {
          title: '千述专业开发团队',
          img: require('../../assets/Mi/home/business/team.png'),
          backgroundColor: '#6C81DA',
          isLine: true,
          content: '多年行业经验，资深产品经理一对一服务成熟技术团队，一站式服务',
          i: 1,
          backgroundStyle: {'background': '#6C81DA'},
        },
        {
          title: 'APP开发',
          img: require('../../assets/Mi/home/business/appDev.png'),
          backgroundColor: '#2ABCB0',
          isLine: true,
          content: '提供IOS、Android、HarmonyOS各系统开发',
          i: 2,
          backgroundStyle: {'background': '#2ABCB0'},
        },
        {
          title: '智能软硬结合系统',
          img: require('../../assets/Mi/home/business/soft.png'),
          backgroundColor: '#5F8ED3',
          isLine: true,
          content: '能物联系统、智慧农业、智慧24小时无人店等',
          i: 3,
          backgroundStyle: {'background': '#5F8ED3'},
        },
        {
          title: '网站定制开发',
          img: require('../../assets/Mi/home/business/html.png'),
          backgroundColor: '#F08D5A',
          isLine: false,
          content: '提供网站建设、模板建站、网络制作及定制开发',
          i: 4,
          backgroundStyle: {'background': '#F08D5A'},
        },
        {
          title: '企业应用管理系统',
          img: require('../../assets/Mi/home/business/company.png'),
          backgroundColor: '#379DBA',
          isLine: false,
          content: '开发业务流程系统、SCRM系统、ERP系统、CRM系统等',
          i: 5,
          backgroundStyle: {'background': '#379DBA'},
        },
        {
          title: '微信小程序',
          img: require('../../assets/Mi/home/business/minipro.png'),
          backgroundColor: '#F4AB8D',
          isLine: false,
          content: '提供微信小程序、支付宝小程序等定制开发',
          i: 6,
          backgroundStyle: {'background': '#F4AB8D'},
        },
      ],
      whyChoose: require('../../assets/Mi/home/whyChoose.png'),
      whyChooseBg: require('../../assets/Mi/home/whyChooseBg.png')
    }
  },
  mounted() {

  },
  methods: {
    sceneTitleChange(title, name) {
      this.sceneTitle = title
      setTimeout(() => {
        this.$emit('navTitleChange1')
        this.$emit('casePopNameChange', name)
      }, 50);

    },
    navChange(title) {
      this.navTitle = title
      this.$emit('navTitleChange')
    },
    start(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    move(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      var nowtime = new Date().getTime();
      if (this.startTime == undefined || nowtime > this.startTime) {
        if (this.startX - this.moveX <= 0) {
          this.prev();
          return false;
        } else {
          this.next();
          return false;
        }
      }
    },
    prev() {
      this.$refs.carouselRef.prev();
      this.startTime = new Date().getTime() + 500;
    },
    next() {
      this.$refs.carouselRef.next();
      this.startTime = new Date().getTime() + 500;
    },
  }
}
</script>
<style lang="scss" scoped>

.mHome {
  .mHome-top {
    width: 100%;
    // height: 484.38PX;
    height: 440px;
    position: relative;
    .topImg {
      width: 100%;
      height: 100%;
      z-index: -10;
    }
    .computer {
      display: flex;
      flex-direction: column;
      align-items: center;
      .screen {
        width: 254px;
        height: 168px;
        position: absolute;
        bottom: 30%;
        // left: 65PX;
        z-index: 2;
        animation: screen 3s infinite;
      }
      .base {
        width: 270px;
        height: 107px;
        position: absolute;
        bottom: 7%;
        z-index: 1;
        // left: 46PX;
      }
      .shade {
        width: 350px;
        height: 150px;
        position: absolute;
        bottom: -4%;
      }
    }
    
    .text {
      width: 100%;
      position: absolute;
      // margin-left: 11.27%;
      // margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      p:nth-child(1) {
        // width: 229px;
        // padding-left: 1PX;
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        color: #006BF5;
        // line-height: 67.99PX;
        letter-spacing: 4.4px;
        font-family: SourceHanSansCN-Medium;
        margin: 34px auto 0 auto;
      }
      div {
        margin-top: 3px;
        width: 151px;
        // height: 12.5px;
        border-top: 2px solid rgba(0, 107, 245, 0.3);
      }
      p:last-child {
        // width: 151px;
        // height: 25px;
        margin: 0;
        padding-top: 7px;
        font-size: 13px;
        letter-spacing: 1.7px;
        font-weight: 500;
        font-family: SourceHanSansCN-Medium;
        color: #006BF5;
      }
      // div {
      //   margin: 10PX auto;
      //   width: 175PX;
      //   height: 2PX;
      //   border-radius: 1PX;
      //   background-color: rgba($color: #006bf5, $alpha: 0.3);
      // }
      // p:nth-child(2) {
      //   margin-top: 39PX;
      //   margin-bottom: 28PX;
      // }
      // p:last-child {
      //   // margin-top: 27PX;
      //   font-family: SourceHanSansCN-Medium;
      //   font-size: 14PX;
      //   color: #005ed8;
      //   letter-spacing: 3PX;
      //   text-align: center;
      // }
    }
  }

  .homeScene {
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .homeScene-top {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        width: 185px;
        height: 29px;
      }
    }
    .homeScene-bottom {
      // padding-left: 128PX;
      // padding-right: 128PX;
      margin-top: 18px;
      .homeScene-bottom-list:nth-child(1) {
        border-bottom: none;
      }
      .homeScene-bottom-list {
        border: 1px solid #e5e5e5;
        display: flex;
        // justify-content: space-between;
        background-color: #fbfdff;
        .homeScene-bottom-list-item:last-child {
          border: none;
        }
        .homeScene-bottom-list-item {
          width: 86px;
          height: 112.5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #e5e5e5;

          img {
            width: 24px;
            height: 24px;
          }
          .homeScene-bottom-list-item-line {
            width: 15px;
            height: 1px;
            background-color: #c6c6c6;
            margin-top: 16px;
            margin-bottom: 8px;
          }
          p {
            font-family: SourceHanSansCN-Medium;
            font-size: 12px;
            color: #000000;
            zoom: 0.83;
          }
        }

        img:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  .homeBusiness {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    .homeBusiness-top {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        width: 185px;
        height: 29px;
      }
    }
    .homeBusiness-bottom {
      margin-top: 20px;
      box-sizing: border-box;

      .homeBusiness-bottom-list {
        margin-left: 15px;
        overflow: hidden;
      }
      
      .homeBusiness-bottom-list-item {
        width: 169px;

        float: left;

        margin-bottom: 7px;
        margin-right: 7px;
        border-radius: 3px 3px 3px 3px;

        .img1 {width: 89px; height: 90px; margin-left: 45px; margin-top: 6px;}
        .img2 {width: 114px; height: 88px; margin-left: 28px; margin-top: 8px;}
        .img3 {width: 98px; height: 89px; margin-left: 42px; margin-top: 7px;}
        .img4 {width: 100px; height: 90px; margin-left: 35px; margin-top: 6px;}
        .img5 {width: 110px; height: 86px; margin-left: 30px; margin-top: 9px;}
        .img6 {width: 77px; height: 90px; margin-left: 55px; margin-top: 9px;}

        .homeBusiness-bottom-list-item-top {
          height: 104px;
        }


        .homeBusiness-bottom-list-item-bottom {
          height: 110px;
          background: #F4F4F4;
          P:first-child {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #989898;
            padding-left: 29px;
            padding-top: 17px;
          }
          div {
            width: 155px;
            height: 1px;
            background: #DFDFDF;
            border-radius: 0.36px;
            margin: 0 auto;
            margin-top: 10px;
          }
          p:last-child {
            font-size: 9px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            margin: 0 auto;
            margin-top: 17px;
            margin-left: 10px;
            padding-bottom: 18px;
          }
        }

      }
 

      // .homeBusiness-bottom1 {
      //   display: flex;
      //   height: 126px;
      // }
      // .homeBusiness-bottom-left {
      //   width: 346px;
      //   z-index: -1;
      //   /deep/ .el-carousel,
      //   /deep/ .el-carousel__container {
      //     height: 126px;
      //   }
      //   /deep/ .el-carousel__indicators--outside {
      //     position: absolute;
      //     bottom: 0px;
      //     left: 40%;
      //     .el-carousel__button {
      //       width: 4px;
      //       height: 2px;
      //       background-color: rgba($color: #fff, $alpha: 0.5);
      //       border-radius: 1px;
      //     }
      //     .is-active {
      //       .el-carousel__button {
      //         width: 8px;
      //         background-color: rgba($color: #fff, $alpha: 1);
      //       }
      //     }
      //   }
      //   .el-carousel__item.is-animating {
      //     // -webkit-transition: -webkit-transform 1s ease-in-out;
      //     // transition: -webkit-transform 1s ease-in-out;
      //     // transition: transform 1s ease-in-out;
      //     // transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
      //   }
      //   img {
      //     width: 346px;
      //     height: 126px;
      //   }
      // }
    }
  }
  .homeService {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .homeService-top {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      img {
        width: 185px;
        height: 29px;
      }
    }
    .homeService-bottom {
      display: flex;
      .homeService-bottom-list:last-child {
        margin-right: 0;
      }
      .homeService-bottom-list {
        display: flex;
        flex-direction: column;
        width: 82px;
        height: 75px;
        // justify-content: space-between;
        position: relative;
        padding-left: 9px;
        box-sizing: border-box;
        margin-right: 5px;

        img {
          width: 82px;
          height: 75px;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: -1;
        }
        p:nth-child(2) {
          color: #000000;
          font-size: 12px;
          // zoom: 0.67;
          font-weight: bold;
          transform: scale(0.67);
          margin-top:16px;
        margin-left:-17px;
          
        }
        p {
          margin-left:-25px;
          color: #ababab;
          font-size: 12px;
          // zoom: 0.5; 
          letter-spacing: 0.5px;
          transform: scale(0.5);
          margin-top:10px;
          white-space:nowrap;
        }
        p:last-child {
          margin-top: 0px;
          margin-top:-8px;

        }
      }
    }
  }
  .homeJob {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    ::-webkit-scrollbar {
      /* 隐藏滚动条 */
      display: none;
    }

    .homeJob-top {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
      img {
        width: 185px;
        height: 29px;
      }
    }
    .homeJob-bottom {
      .homeJob-bottom-list {
        display: flex;
        justify-content: space-between;
        overflow-x: auto;
        padding-top: 5px;
        padding-bottom: 5px;
        height: 210px;
        padding-left: 20px;
        padding-right: 20px;
        .homeJob-bottom-list-item {
          padding-left: 5px;
          padding-right: 5px;
          box-sizing: border-box;
          width: 160px;
          height: 210px;
          background-color: #fff;
          box-shadow: 0px 2px 7px 1px rgba(163, 163, 163, 0.14);
          margin-right: 10px;
          .homeJob-bottom-list-item-top {
            margin-top: 10px;
            margin-bottom: 10px;
            img {
              width: 150px;
              height: 58px;
            }
          }
          .homeJob-bottom-list-item-center {
            display: flex;
            flex-direction: column;
            padding-left: 5px;
            padding-right: 5px;
            box-sizing: border-box;
            p {
              font-size: 15px;
              letter-spacing: 1px;
              font-family: SourceHanSansCN-Medium;
              margin-bottom: 10px;
            }
            span {
              font-size: 10px;
              color: #999999;
              zoom: 0.83;
              line-height: 16px;
            }
          }
          .homeJob-bottom-list-item-bottom {
            margin-left: 5px;
            margin-top: 15px;
            width: 65px;
            height: 19px;
            background: linear-gradient(to right, #0066ff, #2591ff);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 9px;
              height: 9px;
              margin-left: 3px;
            }
            p {
              font-size: 12px;
              color: #ffffff;
              letter-spacing: 0.5px;
              font-family: SourceHanSansCN-Medium;
              zoom: 0.75;
            }
          }
        }
        .homeJob-bottom-list-item:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .homeWhy {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    .homeWhy-top {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
      img {
        width: 185px;
        height: 29px;
      }
    }
    .homeWhy-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      img {
        width: 345px;
        height: 450px;
      }

      .content {
        position: absolute;
        bottom: 20px;
        left: 30px;

        .title {
          font-size: 18px;
          color: #fff;
          font-family: SourceHanSansCN-Bold;
          margin-bottom: 5px;
        }

        p {
          font-size: 9px;
          color: #fff;
          line-height: 20px;
        }
      }
    }
  }
}

@keyframes screen {
  0% {
    transform: translateX(0) translateY(0);
  }
  50% {
    transform: translateX(0) translateY(-5%);
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}
</style>